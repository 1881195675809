.membership-card {
  background: linear-gradient(to bottom, #f3d250, #ffb21a);
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.membership-title {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.membership-details {
  color: #333;
}

.member-name {
  font-size: 18px;
  margin-bottom: 10px;
}

.member-points,
.member-status {
  font-size: 16px;
  margin-bottom: 8px;
}

.member-status {
  font-weight: bold;
}

.membership-card::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 20px;
  z-index: -1;
}
