@media (max-width: 640px) {
  .card-container {
    padding-left: 1rem !important; /* Adjust as needed */
    padding-right: 1rem !important; /* Adjust as needed */
  }
  .main-chart {
    display: none;
  }
}

@media (max-width: 1000px) {
  .table-data {
    flex-direction: column;
  }
  .table-container {
    width: 100% !important;
  }
}
