.file-container {
  cursor: pointer;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the contents */
  transition: 0.3s;
}

.file-image {
  transition: transform 0.3s ease-in-out;
}

.file-container:hover .file-image {
  transform: scale(1.05); /* Scales the image only */
}

.scroll-container {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none; /* para Firefox */
  -ms-overflow-style: none; /* para Internet Explorer y Edge */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* para Chrome, Safari y Opera */
}
